@keyframes turn {
    from {
      transform: rotate3d(0, 0, 0, 0);
    }
    to {
      transform: rotate3d(1, 1, 0, 360deg);
    }
  }
  .cube {
    width: 20px;
    height: 20px;
    transform-style: preserve-3d;
    animation: turn 5s linear infinite;
    margin: 50px;
  }
  .face {
    width: 100px;
    height: 100px;
    background: white;
    border: 1px solid #151515;
    position: absolute;
    opacity: 1;
    transform: translateZ(50px);
    margin: -40px;
  }
  .front {
    transform: translateZ(50px);
  }
  .back {
    transform: translateZ(-50px) rotateY(180deg);
  }
  .left {
    transform: translateX(-50px) rotateY(-90deg);
  }
  .right {
    transform: translateX(50px) rotateY(90deg);
  }
  .top {
    transform: translateY(-50px) rotateX(90deg);
  }
  .bottom {
    transform: translateY(50px) rotateX(-90deg);
  }