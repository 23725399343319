html {
  font-family: AtypDisplay;
  font-size: 14px;
  width: 100vw;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  background-image: url('../static/Bureau.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
}

.error-page {
  min-height: 100vh;
  min-width: 100vw;
}

.nav-height {
  height: 15vh;
}

.full-height {
  height: 84vh;
}

.font-nav {
  font-family: AtypDisplay;
  font-weight: medium;
  font-size: 1rem;
}

.font-medium {
  font-family: AtypDisplay;
  font-weight: medium;
  font-size: 1.5rem;
}

.font-bold {
  font-family: AtypDisplay;
  font-weight: bold;
  font-size: 3rem;
}

.font-small {
  font-family: AtypDisplay;
  font-weight: normal;
  font-size: 1rem;
}

.menu-circle {
  aspect-ratio: 1/1;
  width: 80px;
  height: 80px;
  border: 2px solid gray;
  border-radius: 50%;
}

hr {
  color: black;
  background-color: black;
  height: 2px;
  width: 100px;
  border-color: black;
}

.brd {
  border: 2px solid black;
}

.bg-card {
  background-image: url('../static/CardStroke.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.custom-button {
  color: black !important;
  width: 12.5rem;
  height: 4rem;
  border: 2px solid black !important;
  border-radius: 0px !important;
  background-color: white !important;
  transition-duration: 0.25s !important;
}

.custom-button:hover {
  background-color: blue !important;
  color: white !important;
  border: 2px solid blue !important;
}

.input {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 2px solid black !important;
  background-color: transparent !important;
}

.input:focus {
  box-shadow: none !important;
  border: 0 !important;
  outline: none !important;
  border-bottom: 2px solid blue !important;
  background-color: transparent !important;
}

.iframe {
  position:fixed;
  width:100%;
  height:100%;
  margin:0;
  padding:0;
  overflow:hidden;
  z-index:999999;
}

.modal-window {
  opacity: 0;
  display: none;
}

.modal-window.active {
  opacity: 1;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.anim-fadein {
  animation: fadeIn 0.3s ease-in;
}

@font-face {
  font-family: "AtypDisplay";
  src: local("AtypDisplay"),
    url("./fonts/AtypDisplay-Medium.ttf") format("truetype");
  font-weight: medium;
}

@font-face {
  font-family: "AtypDisplay";
  src: local("AtypDisplay"),
    url("./fonts/AtypDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "AtypDisplay";
  src: local("AtypDisplay"),
    url("./fonts/AtypDisplay-Semibold.ttf") format("truetype");
  font-weight: bold;
}